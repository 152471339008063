import $ from 'jquery';
//import $ from "/node_modules/jquery/dist/jquery.min.js"
import '@popperjs/core';
//import { Nav, Navbar, Alert, Modal, Tab } from 'bootstrap';
import bootstrap from "/node_modules/bootstrap/dist/js/bootstrap.js";
import * as ko from "/node_modules/knockout/build/output/knockout-latest.js";
import "/node_modules/jquery.cookie/jquery.cookie.js";
import "/node_modules/JSON2/json2.js";
import "/node_modules/lazysizes/lazysizes.js";
import * as dayjs from "dayjs";
require("jquery-ui/ui/widgets/datepicker");
import "/node_modules/knockout-mapping/dist/knockout.mapping.js";
import "/Scripts/master/master.js";

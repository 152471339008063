/// <reference path="../../node_modules/@types/jqueryui/index.d.ts"/>
/// <reference path="../../node_modules/@types/bootstrap/index.d.ts"/>
/// <reference path="../../static/ts/kpec.d.ts"/>
function logExtendedError(error, source) {
    var _a, _b, _c;
    if (error instanceof Error) {
        console.log("it is an error");
    }
    var errorObj = error instanceof Error ? error : new Error('Unknown error');
    var url = "/api/exceptionapi";
    var logData = {
        Message: (_a = errorObj === null || errorObj === void 0 ? void 0 : errorObj.message) !== null && _a !== void 0 ? _a : 'Missing message',
        Source: source !== null && source !== void 0 ? source : 'Missing source',
        ErrorType: (_b = errorObj === null || errorObj === void 0 ? void 0 : errorObj.name) !== null && _b !== void 0 ? _b : 'Missing name',
        Stack: (_c = errorObj === null || errorObj === void 0 ? void 0 : errorObj.stack) !== null && _c !== void 0 ? _c : 'No stack trace available',
    };
    // Implement your logging mechanism here, e.g., send to server or log to console
    console.log(logData);
    var postData = JSON.stringify(logData);
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: postData
    })
        .then(function (response) {
        if (!response.ok) {
            //            throw new Error('Network response was not ok');
        }
        //return response.json();
    })
        .catch(function (networkError) {
        console.error('Fetch error:', networkError);
    });
}
// Global error handler for uncaught errors
window.addEventListener('error', function (event) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    event.preventDefault();
    var source = 'Unhandled/g';
    if (event instanceof ErrorEvent) {
        if (event.error) {
            source = (_b = (_a = event === null || event === void 0 ? void 0 : event.error) === null || _a === void 0 ? void 0 : _a.fileName) !== null && _b !== void 0 ? _b : 'Unhandled/g';
            var line = (_d = (_c = event === null || event === void 0 ? void 0 : event.error) === null || _c === void 0 ? void 0 : _c.lineNumber) !== null && _d !== void 0 ? _d : 0;
            source = ((_e = event === null || event === void 0 ? void 0 : event.error) === null || _e === void 0 ? void 0 : _e.fileName) + ' : ' + line.toString();
            var message = (_g = (_f = event === null || event === void 0 ? void 0 : event.error) === null || _f === void 0 ? void 0 : _f.message) !== null && _g !== void 0 ? _g : 'missing message';
            var stack = (_j = (_h = event === null || event === void 0 ? void 0 : event.error) === null || _h === void 0 ? void 0 : _h.stack) !== null && _j !== void 0 ? _j : '';
            message = message + stack;
            var newError = new Error(message);
            newError.lineNumber = line;
            newError.fileName = source;
            newError.stack = stack;
            newError.name = 'Unhandled';
            logExtendedError(newError, source);
        }
        else {
            // these seems to be stubs of errorEvents from non-DM sources.  Ignore this garbage!
        }
    }
    if (event instanceof Error) {
        logExtendedError(event, source);
    }
    if (!(event instanceof Error) && !(event instanceof ErrorEvent)) {
        // Handle other error events
        var errorMessage = typeof event === 'object' ? JSON.stringify(event) : String(event);
        logExtendedError(new Error(errorMessage), source);
    }
});
// Add handler for unhandled promise rejections
window.addEventListener('unhandledrejection', function (event) {
    event.preventDefault();
    var reason = event.reason;
    if (reason instanceof Error) {
        logExtendedError(reason, 'promise');
    }
    else {
        var errorMessage = typeof reason === 'object' ? JSON.stringify(reason) : String(reason);
        var logData = {
            Message: JSON.stringify(event.reason)
        };
        LogJSError(logData);
    }
});
function LogJSError(data) {
    if (!data.Message)
        return;
    fetch('/api/exceptionapi/jserror', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(data)
    })
        .then(function (response) { return response.json(); })
        .then(function (result) { return console.log('Error logged:', result); })
        .catch(function (error) { return console.error('Failed to log error:', error); });
}
// Helper function to extract the line number from the stack trace string
function extractLineNumberFromStack(stack) {
    var lines = stack.split('\n');
    for (var i = 0; i < lines.length; i++) {
        if (lines[i].includes('at')) {
            var match = lines[i].match(/:(\d+):\d+/);
            return match ? match[1] : undefined;
        }
    }
    return undefined;
}
window.global = window; //https://github.com/auth0/auth0.js/issues/753
function LogException(error) {
    logExtendedError(error, "Unhandled");
}
window.LogException = LogException;
window.LogJSError = LogJSError;
if (!Date.prototype.toISOString) {
    (function () {
        function pad(number) {
            try {
                var r = String(number);
                if (r.length === 1) {
                    r = '0' + r;
                }
                return r;
            }
            catch (err) {
                if (err instanceof Error) {
                    LogException(err);
                }
                else {
                    var errorMessage = typeof err === 'object' ? JSON.stringify(err) : String(err);
                    var logData = {
                        Message: errorMessage,
                        Source: "master num pad",
                    };
                    LogJSError(logData);
                }
            }
        }
        try {
            Date.prototype.toISOString = function () {
                return this.getUTCFullYear()
                    + '-' + pad(this.getUTCMonth() + 1)
                    + '-' + pad(this.getUTCDate())
                    + 'T' + pad(this.getUTCHours())
                    + ':' + pad(this.getUTCMinutes())
                    + ':' + pad(this.getUTCSeconds())
                    + '.' + String((this.getUTCMilliseconds() / 1000).toFixed(3)).slice(2, 5)
                    + 'Z';
            };
        }
        catch (err) {
            if (err instanceof Error) {
                LogException(err);
            }
            else {
                var errorMessage = typeof err === 'object' ? JSON.stringify(err) : String(err);
                var logData = {
                    Message: errorMessage,
                    Source: "master date proto",
                };
                LogJSError(logData);
            }
        }
    }());
}
Date.now = Date.now || function () { return +new Date; };
function setIframeHeight() {
    var helperUrlElement = document.getElementById('HdnIFrameHelperUrl');
    if (helperUrlElement) {
        var parent_url_elem = document.getElementById('HdnIFrameHelperUrl');
        if (parent_url_elem) {
            var parent_url = parent_url_elem.value;
            if (parent_url) {
                window.parent.postMessage({ if_height: $('body').outerHeight(true) }, "*");
            }
        }
    }
}
window.setIframeHeight = setIframeHeight;
function isLocalStorageSupported() {
    try {
        var storage = window.localStorage;
        if (!storage)
            return false;
        // Attempt to write and read data to test functionality
        var testKey = '__ls_test__';
        storage.setItem(testKey, '1');
        storage.removeItem(testKey);
        return true;
    }
    catch (error) {
        // Catch any errors including quota exceeded or permission denied
        console.error('LocalStorage not supported:', error);
        return false;
    }
}
window.isLocalStorageSupported = isLocalStorageSupported;
function clearOldCachedSearchResults() {
    if (isLocalStorageSupported() === true) {
        var dmvm = localStorage.getItem("dmViewModel");
        if (dmvm) {
            var viewModelJS = JSON.parse(dmvm);
            if (viewModelJS.totaldoulas != null) {
                if (viewModelJS.doulasThisPage[0] != null) {
                    if (viewModelJS.doulasThisPage[0].BirthFeeSwedish === null) {
                        if (localStorage.getItem("doulaPageIndex") !== null) {
                            localStorage.removeItem("doulaPageIndex");
                        }
                        localStorage.removeItem("dmViewModel");
                    }
                }
            }
        }
    }
}
window.clearOldCachedSearchResults = clearOldCachedSearchResults;
function clearLocalStorage() {
    if (isLocalStorageSupported() === true) {
        if (localStorage.getItem("dmLandingThumbs") !== null) {
            localStorage.removeItem("dmLandingThumbs");
        }
        if (localStorage.getItem("doulaPageIndex") !== null) {
            localStorage.removeItem("doulaPageIndex");
        }
        if (localStorage.getItem("dmViewModel") !== null) {
            localStorage.removeItem("dmViewModel");
        }
    }
}
window.clearLocalStorage = clearLocalStorage;
// old cookie code
// function createCookie(name: string, value: string, days: number) {
//     var expires = "";
//     if (days) {
//         var date = new Date();
//         date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
//         expires = ";expires=" + date.toUTCString();
//     }
//     document.cookie = name + "=" + value + expires + ";secure;SameSite=None;path=/";
// }
// window.createCookie = createCookie;
// function readCookie(name: string) {
//     var nameEQ = name + "=";
//     var ca = document.cookie.split(';');
//     for (var i = 0; i < ca.length; i++) {
//         var c = ca[i];
//         while (c.charAt(0) == ' ')
//             c = c.substring(1, c.length);
//         if (c.indexOf(nameEQ) === 0) {
//             return c.substring(nameEQ.length, c.length);
//         }
//     }
//     return null;
// }
// window.readCookie = readCookie;
// function eraseCookie(name: string) {
//     createCookie(name, "", -1);
// }
// window.eraseCookie = eraseCookie;
function createCookie(name, value, days) {
    var secure = true;
    var sameSite = 'None';
    var path = '/';
    var expires = days !== undefined ? ";expires=".concat(new Date(Date.now() + (days * 24 * 60 * 60 * 1000)).toUTCString()) : '';
    document.cookie = "".concat(name, "=").concat(value).concat(expires, ";secure=").concat(secure ? 'true' : 'false', ";SameSite=").concat(sameSite, ";path=").concat(path);
}
window.createCookie = createCookie;
function readCookie(name) {
    var encodedName = encodeURIComponent(name);
    // Split cookies by semicolon and trim whitespace from each
    var cookies = document.cookie.split(';').map(function (cookie) { return cookie.trim(); });
    for (var _i = 0, cookies_1 = cookies; _i < cookies_1.length; _i++) {
        var cookie = cookies_1[_i];
        // Check if the current cookie starts with the target name followed by '='
        if (cookie.startsWith("".concat(encodedName, "="))) {
            var valuePart = cookie.substring(encodedName.length + 1);
            try {
                return valuePart;
            }
            catch (error) {
                // Handle potential decoding errors gracefully
                return null;
            }
        }
    }
    return null; // Return null if no matching cookie is found
}
window.readCookie = readCookie;
function eraseCookie(name) {
    var secure = true;
    var sameSite = 'None';
    var path = '/';
    var encodedName = encodeURIComponent(name);
    document.cookie = "".concat(encodedName, "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=").concat(path, ";SameSite=").concat(sameSite);
}
window.eraseCookie = eraseCookie;
function checkAndPurgeOldClientCache() {
    try {
        var dmCookieObj = {};
        var dmCookie = readCookie('doulamatch');
        //var dmCookieObj = {};
        if (dmCookie) {
            dmCookieObj = JSON.parse(dmCookie);
            if (dmCookieObj.created) {
                if (dmCookieObj.created < 1581130651) {
                    eraseCookie("doulamatch");
                    clearLocalStorage();
                }
            }
        }
    }
    catch (err) {
        if (err instanceof Error) {
            LogException(err);
        }
        else {
            var errorMessage = typeof err === 'object' ? JSON.stringify(err) : String(err);
            var logData = {
                Message: errorMessage,
                Source: "master checkandpurge",
            };
            LogJSError(logData);
        }
    }
}
window.checkAndPurgeOldClientCache = checkAndPurgeOldClientCache;
function setSearchPage(pageIndex) {
    var dmCookie = readCookie('doulamatch');
    var dmCookieObj = {};
    if (!dmCookie) {
        dmCookieObj.created = new Date().getTime() / 1000;
    }
    else {
        dmCookieObj = JSON.parse(dmCookie);
    }
    dmCookieObj.searchPageIndex = pageIndex;
    dmCookie = JSON.stringify(dmCookieObj);
    createCookie('doulamatch', dmCookie, 15);
}
window.setSearchPage = setSearchPage;
function setDoulaType(doulaType) {
    var dmCookie = readCookie('doulamatch');
    var dmCookieObj = {};
    if (!dmCookie) {
        dmCookieObj.created = new Date().getTime() / 1000;
    }
    else {
        dmCookieObj = JSON.parse(dmCookie);
    }
    if (dmCookieObj.doulaType) {
        if (dmCookieObj.doulaType != doulaType) {
            dmCookieObj.searchPageIndex = 0;
            if (window.localStorage !== undefined) {
                if (localStorage.getItem("dmViewModel")) {
                    localStorage.removeItem("dmViewModel");
                }
            }
        }
    }
    dmCookieObj.doulaType = doulaType;
    dmCookieObj.lastUpdated = new Date().getTime() / 1000;
    dmCookie = JSON.stringify(dmCookieObj);
    createCookie('doulamatch', dmCookie, 15);
}
window.setDoulaType = setDoulaType;
function dismissSearchDateCheck() {
    var dmCookie = readCookie('doulamatch');
    var dmCookieObj = {};
    if (!dmCookie) {
        dmCookieObj.created = new Date().getTime() / 1000;
    }
    else {
        dmCookieObj = JSON.parse(dmCookie);
    }
    dmCookieObj.searchDateCheckDismissed = true;
    dmCookie = JSON.stringify(dmCookieObj);
    createCookie('doulamatch', dmCookie, 15);
}
window.dismissSearchDateCheck = dismissSearchDateCheck;
function dismissCBECheck() {
    var dmCookie = readCookie('doulamatch');
    var dmCookieObj = {};
    if (!dmCookie) {
        dmCookieObj.created = new Date().getTime() / 1000;
    }
    else {
        dmCookieObj = JSON.parse(dmCookie);
    }
    dmCookieObj.cbeCheckDismissed = new Date();
    dmCookie = JSON.stringify(dmCookieObj);
    createCookie('doulamatch', dmCookie, 15);
}
window.dismissCBECheck = dismissCBECheck;
document.addEventListener('DOMContentLoaded', function () {
    var btn = document.getElementById('btnAcceptTerms');
    if (btn) {
        btn.addEventListener('click', function () {
            fetch("/doulacalendar.asmx/DismissReminder", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8"
                },
                body: JSON.stringify({ type: "Terms" })
            }).then(function (response) { return response.json(); })
                .catch(function (error) { return console.error('Error:', error); });
            $("#TermsPrompt").hide("slow");
        });
    }
    var tePromo = document.getElementById('TrainingEventPromoContainer');
    if (tePromo) {
        document.addEventListener('DOMContentLoaded', function () {
            var trainingEventPromo = document.getElementById('TrainingEventPromo');
            if (trainingEventPromo) {
                trainingEventPromo.addEventListener('hidden.bs.alert', function () {
                    fetch("/doulacalendar.asmx/DismissReminder", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json; charset=utf-8"
                        },
                        body: JSON.stringify({ type: "TrainingEventPromo" })
                    }).then(function (response) { return response.json(); })
                        .catch(function (error) { return console.error('Error:', error); });
                });
            }
        });
    }
});
function DismissExpiredTestimonialReminder() {
    fetch("/doulacalendar.asmx/DismissReminder", {
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8"
        },
        body: JSON.stringify({ type: "ExpiredTestimonial" })
    }).then(function (response) { return response.json(); })
        .catch(function (error) { return console.error('Error:', error); });
    $("#ExpiredCertHint").hide();
}
function DismissTrainingEventPromo() {
    fetch("/doulacalendar.asmx/DismissReminder", {
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8"
        },
        body: JSON.stringify({ type: "TrainingEventPromo" })
    }).then(function (response) { return response.json(); })
        .catch(function (error) { return console.error('Error:', error); });
    $("#TrainingEventPromo").hide();
}
window.DismissExpiredTestimonialReminder = DismissExpiredTestimonialReminder;
if (window.location.search.indexOf('iframe') > 0) {
    setIframeHeight();
}
setIframeHeight();
var unPaid = document.getElementById("unpaidModal");
if (unPaid) {
    var modal = bootstrap.Modal.getOrCreateInstance(unPaid, {
        backdrop: 'static',
        focus: true,
        keyboard: false
    });
    modal.show();
}
